import * as React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ContactsSection from "../components/sections/ContactsSection";
import Footer from "../components/sections/Footer";

const ContactsEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"en"} pageTitle={"Contact with us"} description={""} />
    <Header lang={"en"} />
    <ContactsSection
      title={"Contact with us"}
      subtitle={""}
      formTitle={""}
      phone={"+46 (0)70 594 29 54"}
      email={"info@posplus.se"}
      firstnameLabel={"Your name"}
      emailLabel={"Email"}
      phoneLabel={"Phone number"}
      subjectMail={"Request from Contacts"}
      submitButton={"Submit"}
      messageLabel={"Message"}
      submitButtonSuccess={"Sent"}
    />
    <Footer lang={"en"} />
  </div>
);

export default ContactsEnPage;
